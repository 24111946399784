export default class extends ApplicationController {
  static targets = [ "link", "onBookshelfLinkTemplate", "addToBookShelfFormTemplate" ]

  connect() {
    debugger
  }

  update(event) {
    const template = this.onBookshelfLinkTemplateTarget.content.cloneNode(true)
    this.linkTarget.innerHTML = ""
    this.linkTarget.appendChild(template)
  }
}
