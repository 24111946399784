import { createPopper } from "@popperjs/core"
import { collapseExpansion, collapseAllExpansions } from "../lib/collapse_expansions"
import { ESCAPE } from "../lib/keys"

export default class extends ApplicationController {
  createDropdown(element, dropdown, options = {}) {
    const placement = options.placement || "bottom"

    createPopper(element, dropdown, {
      placement: placement,
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: []
          }
        }
      ]
    })
  }

  closeAllPopups(e) {
    e.stopPropagation()
    document
      .querySelectorAll("[aria-expanded='true']")
      .forEach((expandedElement) => {
        if (expandedElement.contains(e.target)) {
          // do nothing
        } else {
          collapseExpansion(expandedElement)
        }
      })
  }

  closeAllPopupsOnEscape(e) {
    if (e.key === ESCAPE) {
      collapseAllExpansions()
    }
  }
}

