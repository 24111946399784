export default class extends ApplicationController {
  static targets = [ "reviewContent" ]
  static values = { inventoryBookId: String }


  edit(event) {
    this.cancel(event)
    this.element.replaceWith(this.reviewForm)
  }

  // Getters
  get reviewForm() {
    return document.querySelector(`form#book-review-${this.inventoryBookIdValue}`)
  }
}
