export const isFuzzyMatch = (query, reference) => {
  query = query.toLowerCase()?.trim()
  reference = reference.toLowerCase()?.trim()
  return (isSubSequenceOf(query, reference))
}

const isSubSequenceOf = (query, str) => {
  const queryLength = query.length
  const strLength = str.length

  let i = 0
  let j = 0
  for (i = 0; i < strLength && j < queryLength; i++) {
    if (query.charAt(j) == str.charAt(i)) j++
  }

  return (j == queryLength)
}
