export default class extends ApplicationController {
  connect() {
    setTimeout(() => {
      var flash = document.getElementById("flashes")
      if (!flash) return
      document.getElementById("flashes").style.display = "none"
    }, this.flashTimeout)
  }

  get flashTimeout() {
    return 4000
  }
}

