export default class extends ApplicationController {
  static targets = [ "content" ]

  show(event) {
    this.cancel(event)
    this.contentTarget.classList.remove("hidden")
  }

  hide(event) {
    this.cancel(event)
    this.contentTarget.classList.add("hidden")
  }
}
