import { createPopper } from "@popperjs/core"
import { collapseExpansion, collapseAllExpansions } from "../lib/collapse_expansions"
import { ESCAPE } from "../lib/keys"

const HIDDEN = "hidden"
const DROPDOWN_MENU_IDENTIFIER = "dropdown-menu"

export default class extends ApplicationController {
  static targets = [ "input", "button", "template", "selectionValueField" ]
  static values = { defaultSelection: String }

  connect() {
    this.data.set("open", false)
    this.applyDefaultSelection()
  }

  disconnect() {
    this.close()
  }

  applyDefaultSelection() {
    if (!this.hasDefaultSelectionValue) return

    this.applySelectionTemplateForName(this.defaultSelectionValue)
  }

  toggle() {
    if (this.isClosed) {
      this.open()
    } else if (this.isOpen) {
      this.close()
    }
  }

  open() {
    this.createDropdown(this.element, this.menu, { placement: this.placement })
    this.showMenu()
    this.applyAriaLabels()
    this.focusInput()
  }

  close() {
    this.hideMenu()
  }

  updateSelection(event) {
    const selectionName = event.currentTarget.dataset.optionName
    if (!selectionName) return

    this.applySelectionTemplateForName(selectionName)

    this.close()
  }

  // Private

  applySelectionTemplateForName(name) {
    const template = this.templateTargets.find((template) => template.dataset.name === name)

    this.buttonTarget.innerHTML = ""
    this.buttonTarget.append(template.content.cloneNode(true))

    this.updateSelectionForm(name)
  }

  updateSelectionForm(value) {
    if (!value) return
    if (!this.hasSelectionValueFieldTarget) return

    this.selectionValueFieldTarget.value = value
  }

  // Actions
  createDropdown(element, dropdown, options = {}) {
    const placement = options.placement || "bottom"
    dropdown.classList.add("z-10") // Add z-index

    createPopper(element, dropdown, {
      placement: placement,
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: []
          }
        },
        {
          name: "offset",
          options: {
            offset: [0, 4]
          }
        }
      ]
    })
  }

  showMenu() {
    this.menu.classList.remove(HIDDEN)
  }

  applyAriaLabels() {
    this.menu.setAttribute("aria-expanded", true)
  }

  hideMenu() {
    this.menu.classList.add(HIDDEN)
  }

  closeOnClickOutside({ target }) {
    if (!this.element.contains(target)) this.close()
  }

  closeOnEscape(event) {
    if (this.isClosed) return
    if (event.key === "Escape") this.close()
  }

  closeAllPopups(e) {
    e.stopPropagation()
    document
      .querySelectorAll("[aria-expanded='true']")
      .forEach((expandedElement) => {
        if (expandedElement.contains(e.target)) {
          // do nothing
        } else {
          collapseExpansion(expandedElement)
        }
      })
  }

  closeAllPopupsOnEscape(e) {
    if (e.key === ESCAPE) {
      collapseAllExpansions()
    }
  }

  focusInput() {
    if (this.hasInputTarget) this.inputTarget.focus()
  }

  // Getters
  get placement() {
    return this.data.get("placement")
  }

  get isOpen() {
    return !this.menu.classList.contains(HIDDEN)
  }

  get isClosed() {
    return !this.isOpen
  }

  get menu() {
    return this.element.querySelector(DROPDOWN_MENU_IDENTIFIER)
  }
}
