export default class extends ApplicationController {
  static targets = [ "content", "expandButton", "contentTemplate", "showMoreButtonTemplate", "showLessButtonTemplate" ]
  static values = { limit: Number, expanded: Boolean }

  connect() {
    this.truncate()
  }

  toggle(event) {
    this.cancel(event)
    this.expandedValue ? this.truncate() : this.expand()
  }

  // Private

  expand() {
    const template = this.contentTemplateTarget.content.cloneNode(true)
    this.contentTarget.innerHTML = ""
    this.contentTarget.appendChild(template)
    this.expandedValue = true
    this.setButton()
  }

  truncate() {
    const template = this.contentTemplateTarget.content.cloneNode(true)
    this.contentTarget.textContent = `${template.textContent.slice(0, this.limitValue)}...`
    this.expandedValue = false
    this.setButton()
  }

  setButton() {
    const template = this.expandedValue ? this.showLessButtonTemplateTarget : this.showMoreButtonTemplateTarget
    this.expandButtonTarget.innerHTML = ""
    this.expandButtonTarget.appendChild(template.content.cloneNode(true))
  }
}
