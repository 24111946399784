export const collapseAllExpansions = (options = {}) => {
  var expandedElements = document.querySelectorAll("[aria-expanded='true']")
  const except = options.except

  if (except !== undefined) {
    expandedElements = Array.from(expandedElements).filter(
      (el) => el !== except
    )
  }

  if (expandedElements.length === 0) {
    return
  }

  expandedElements.forEach((el) => el.classList.add("hidden"))
}

export const collapseExpansion = (expansion) => {
  expansion.classList.add("hidden")
}
