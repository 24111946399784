import "stylesheets/application.scss"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("trix")
require("@rails/actiontext")
require("application")

Chartkick.configure(
  {mapsApiKey: process.env.GOOGLE_MAPS_API_KEY}
)

Chartkick.options = {
  html: "<div id=\"%{id}\" style=\"\">%{loading}</div>"
}