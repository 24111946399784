import { isFuzzyMatch } from "../lib/fuzzy_search_matcher"

export default class extends ApplicationController {
  static targets = [ "input", "list", "searchable", "parent" ]

  performSearch() {
    this.searchableTargets.forEach((searchable) => {
      let notMatch = this.query && !isFuzzyMatch(this.query, searchable.dataset.name)
      searchable.style.display = notMatch ? "none" : "flex"
    })
    this.toggleParentElements()
  }

  abortSearchOnClose() {
    this.inputTargets.forEach((input) => input.value = "")
    this.performSearch()
  }

  // Private

  toggleParentElements() {
    this.hideEmptyParentElements()
    this.showNonEmptyParents()
  }

  hideEmptyParentElements() {
    this.emptyParentElements.forEach((parent) => parent.hidden = true)
  }

  showNonEmptyParents() {
    this.parentTargets.filter((parent) => !this.emptyParentElements.includes(parent)).forEach((parent) => parent.hidden = false)
  }

  get emptyParentElements() {
    return this.parentTargets.filter((parent) => Array.from(parent.querySelector("ul").querySelectorAll("li")).every((e) => e.hidden))
  }

  get searchListArray() {
    return Array.from(this.searchableTargets)
  }

  get query() {
    return this.inputTarget.value.trim()?.toLowerCase()
  }
}